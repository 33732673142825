export default {
  async selectTiempoTrabajado (Vue, idtiempoTrabajado) {
    const tables = Vue.$offline.db.tables
    return (await Vue.$offline.db
      .select()
      .from(tables.tiempo_trabajado)
      .innerJoin(
        tables.tecnico,
        tables.tiempo_trabajado.idempleado.eq(tables.tecnico.idempleado)
      )
      .innerJoin(
        tables.empleado,
        tables.tiempo_trabajado.idempleado.eq(tables.empleado.idempleado)
      )
      .leftOuterJoin(
        tables.ttarea,
        tables.tiempo_trabajado.idttarea.eq(tables.ttarea.idttarea)
      )
      .leftOuterJoin(
        tables.subsis,
        tables.tiempo_trabajado.idsubsis.eq(tables.subsis.idsubsis)
      )
      .leftOuterJoin(
        tables.parte_trabajo,
        tables.tiempo_trabajado.idparte_trabajo.eq(tables.parte_trabajo.idparte_trabajo)
      )
      .leftOuterJoin(
        tables.vehiculo,
        tables.vehiculo.idvehiculo.eq(tables.tiempo_trabajado.idvehiculo)
      )
      .where(tables.tiempo_trabajado.idtiempo_trabajado.eq(idtiempoTrabajado))
      .exec())[0]
  },
}
